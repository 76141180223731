import Framework from '@ssstudio/game-framework/src/Framework';
import {GAME_VERSION} from './version';

export default class Game {
    constructor(container: HTMLElement) {
        console.log('Game constructor');

        let framework: Framework;

        const urlSearchParams = new URLSearchParams(window.location.search);

        let assetsOrigin = window.location.origin;
        if (!urlSearchParams.has('localDevelop')) {
            assetsOrigin = `${document.location.protocol}//${document.location.host}/games/001-sticky-wilds/assets`;
        }

        framework = new Framework({
            gameName: 'sugar-heaven',
            displayName: 'Sugar Heaven',
            gameVersion: GAME_VERSION,
            gameContainer: container,
            assetServerUrl: assetsOrigin
        });

        framework.initFramework();
    }
}